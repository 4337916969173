import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import BlogLayout from '../layouts/blog-layout';

export const query = graphql`
  query($pathSlug: String!) {
    mdx(slug: { eq: $pathSlug }) {
      slug
      frontmatter {
        title
        createDate(formatString: "DD MMMM YYYY")
        updateDate(formatString: "DD MMMM YYYY")
        path
      }
      body
      tableOfContents
    }
  }
`;

const Template = ({ data: { mdx } }) => {
  // console.log('RESOURSE TEMPLATE mdx------', mdx);

  const { slug, frontmatter, body } = mdx;

  console.log('slug', slug);

  return (
    <>
      <br />
      <BlogLayout frontmatter={frontmatter}>
        <MDXRenderer>{body}</MDXRenderer>
      </BlogLayout>
    </>
  );
};

export default Template;
