import React from 'react';
import styles from './blog-layout.module.scss';

const BlogLayout = ({ children, frontmatter }) => {
  return (
    // <div>
    <div className={styles.blogLayout}>
      <div className="blog container mx-auto px-4 my-10 h-auto">
        <div className={`grid grid-cols-8 sm:grid-cols-12 gap-4`}>
          <main className="col-span-9 p-4 rounded-lg bg-white  mx-2">
            {children}
          </main>
          {frontmatter.updateDate && (
            <div className="col-span-3 invisible sm:visible">
              <div className="p-4 mb-2 rounded-lg bg-white border border-solid border-gray-200 text-center">
                <strong>Utima Actualización</strong>
                <br />
                {frontmatter.updateDate}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BlogLayout;
